/*
Project : TheNftAgency
FileName :  loader.component.ts
Author : GlobalVox

CopyRights : GlobalVox
Purpose : This is the component file which used to set loader on screens
*/
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
