<div class="appContainer">
  <div class="nft-main">
    <div class="nft-banner">
      <div class="container-fluid">
        <ul class="banner_lists">
          <li>
            <h2>
              Explore, collect & sell the most premium and exclusive
              <span>NFTs</span><br />
            </h2>
            <p>
              The NFT Agency is your one-stop destination for creating and
              trading NFTs. The NFTs provided cover digital art, collectibles,
              GameFi, the metaverse and much more.
            </p>
            <a href="javascript:void(0)" routerLink="/marketplace">Explore</a>
            <a href="javascript:void(0)" (click)="openCreate()">Create</a>
          </li>
          <li>
            <img src="assets/images/banner_images.png" />
          </li>
        </ul>
      </div>
    </div>
    <div class="nft-trending" *ngIf="collections.length > 0">
      <div class="nft-trending-head">
        <div class="container">
          <h2>Notable collections</h2>
          <ul class="top_tabs">
            <!-- <li [ngClass]="{'active':collectionMenu == ''}" (click)="showItemByCollection('')"><a href="javascript:void(0)">All</a></li> -->
            <div *ngFor="let item of collections">
              <li
                *ngIf="item.item_count > 0"
                [ngClass]="{ active: item._id == collectionMenu }"
                (click)="showItemByCollection(item._id)"
              >
                <a href="javascript:void(0)">{{ item.name }}</a>
              </li>
            </div>
          </ul>
        </div>
      </div>
      <div class="nft-trending_body">
        <div class="container">
          <app-itemskeleton *ngIf="loading"></app-itemskeleton>
          <div class="row" *ngIf="!loading && collectionItems.length > 0">
            <div class="col-md-4" *ngFor="let item of collectionItems">
              <app-item [item]="item" (itemEvent)="itemEvent($event)">
              </app-item>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="nft-category">
      <div class="nft-category-head">
        <div class="container">
          <h2>Browse By Category</h2>
        </div>
      </div>
      <div class="nft-category-content">
        <div class="container">
          <div class="row">
            <div class="col-md-3" *ngFor="let category of categories">
              <div
                class="item"
                [routerLink]="['/marketplace']"
                [queryParams]="{ category_id: category._id }"
              >
                <div class="nft-category-listing">
                  <img
                    [src]="
                      mediaBase +
                      '/images/categories/' +
                      category.category_image
                    "
                  />
                  <div class="nft-category-listing-overlay"></div>
                  <ul class="ntf-category-foot">
                    <li>
                      <h4>{{ category.title }}</h4>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="nft-sell">
      <div class="nft-sell-head">
        <div class="container">
          <h2>NFT 101</h2>
          <p>Get comfortable with the basics.</p>
        </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="nft-sell-blocks">
              <div class="nft-sell-image">
                <img src="assets/images/wallet.png" />
              </div>
              <h4>Setup your Wallet</h4>
              <p>
                To enter the world of NFTs, you need to connect to Metamask.
                Once done, make sure to switch over to the
                <b>Goerli</b> network, make sure you've got funds and voila,
                you're good to go!
              </p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="nft-sell-blocks">
              <div class="nft-sell-image">
                <img src="assets/images/collections.png" />
              </div>

              <h4>Create your collection</h4>
              <p>
                In order to mint NFTs, you need to first define the collection
                it'll belong to. If you've already done that, you can skip it
                and mint your NFTs!
              </p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="nft-sell-blocks">
              <div class="nft-sell-image">
                <img src="assets/images/nfts.png" />
              </div>

              <h4>Add your Nfts</h4>
              <p>
                Once your collection's published, you can get to minting NFTs
                into it.
              </p>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="nft-sell-blocks">
              <div class="nft-sell-image">
                <img src="assets/images/sale.png" />
              </div>

              <h4>List Them for Sale</h4>
              <p>
                After you've filled in all the details required for your NFT,
                upload images that represent your NFT, and voila, it's minted
                and can now be viewed on the marketplace.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="nft-bottom-block">
      <p>Copyright GlobalVox @2024</p>
    </div>
  </div>
</div>
