/*
Project : TheNftAgency
FileName :  config.ts
Author : GlobalVox

CopyRights : GlobalVox
Purpose : This is the file which contain all global constants used in the application
*/
export const config = {
  site_name: 'The-NFT-Agency',
  media_path: 'https://thenftagency-api.theblockchain.team',
  base_url: 'https://thenftagency.theblockchain.team',
  google_client_id:
    '463197681074-rt0537fuvclatgjes4mct01gf19ja8ar.apps.googleusercontent.com',
  facebook_client_id: '370223044026040',
  blockchain: 'Sepolia',
  blockchain_url: 'https://sepolia.etherscan.io/',
  onboard: {
    key: 'e94e20eb-c578-4131-9ba7-b603e5dd2cb0',
    network: 11155111,
  },
  main_address: '0xfE8264995c290188d4db357a470da4Fd759e7142',
  multi_contract_address: '0xF25C4dD051fAE631Df034b19FFb2d5FC8dBb8837',
};
